@import '../styles/variables';
@import '../styles/mixins';

.cart {
  width: 95vw;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  gap: 1rem;
  max-width: 1440px;
  padding-top: $margin-top-page;
  &__empty {
    @include box;
    font-family: 'GameCube';
    margin: 3rem;
    padding: 2rem 1rem;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    gap: 1rem;
    text-align: center;
    & a {
      padding: 1rem;
    }
    & a:hover {
      text-decoration: underline !important;
    }
  }
  &__rightSection {
    display: flex;
    justify-content: center;
    position: relative;
    color: white;
    width: 25%;
    height: 100%;
    @include summary;
    &__background {
      position: fixed;
      top: 0;
      background: no-repeat url('../images/main.jpg');
      background-size: cover;
      width: 22.5%;
      max-width: 350px;
      height: 100vh;
    }
  }
  &__leftSection {
    width: 75%;
    display: flex;
    flex-direction: column;
    align-items: center;
    & > h2 {
      width: 100%;
      font-weight: 400;
      color: white;
      margin: 1rem 0;
      & > span {
        font-size: 1rem;
      }
    }
    & > button {
      width: 10rem;
    }
    gap: 1rem;
    & li {
      list-style: none;
    }
    &__customer {
      display: flex;
      gap: 10px;
      padding: 1rem 0;
      color: white;
      &__billingForm {
        &__addressForm {
          &__createAccount {
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;
            gap: 0.5rem;
          }
        }
      }
    }
    &__toConnect {
      display: flex;
      flex-direction: column;
      gap: 10px;
      padding: 1rem 0;
      &__title {
        color: white;
        display: flex;
        gap: 10px;
        padding-bottom: 1rem;
      }
    }
    @include customerInfo;
    @include cartDetails;
  }
  &__payment {
    width: 100%;
    & > form {
      display: flex;
      flex-direction: column;
      gap: 1rem;
    }
  }
}

.CardField-number {
  background: black;
}
