@import './variables';
@import './mixins';

.confirmOrder {
  margin-top: 5vh;
  width: 95vw;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  & h1 {
    color: white;
    padding-top: 2vh;
    @media (max-width: 800px) {
      text-align: center;
    }
  }
  &__info {
    padding: 1rem;
    @include box;
    display: flex;
    justify-content: space-between;
    @media (max-width: 800px) {
      align-items: center;
    }
    &__cart {
      width: 75%;
      @media (max-width: 800px) {
        width: 100%;
      }
      @include cartDetails;
    }
    @include summary;
    &__summary {
      position: static;
      width: auto;
      color: white;
    }
    @media (max-width: 800px) {
      flex-direction: column;
    }
  }
  &__additional,
  &__shippingInfo {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    padding: 1rem;
    @include box;
    text-align: center;
  }
}
.order-info {
  border-bottom: 1px solid #ccc;
  padding-bottom: 1rem;
  margin-bottom: 1rem;
}

.order-info h2 {
  margin-bottom: 1rem;
}

.order-info table {
  width: 100%;
  border-collapse: collapse;
}

.order-info table th,
.order-info table td {
  padding: 0.5rem;
  border: 1px solid #ccc;
}

.order-info table th {
  text-align: left;
  font-weight: bold;
}

.order-info table tbody tr:nth-child(even) {
  background-color: #f9f9f9;
}

.order-info table tfoot tr:last-child td:first-child {
  font-weight: bold;
}

.shipping-info h2,
.additional-info h2 {
  margin-bottom: 0.5rem;
}

.additional-info p {
  margin-top: 0.5rem;
}

address {
  font-style: normal;
  margin-bottom: 1rem;
}

footer {
  background-color: #eee;
  text-align: center;
  padding-top: 1rem;
  padding-bottom: 1rem;
  margin-top: 1rem;
}
