@import './variables';
@import './typography';

@mixin product {
  .product {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100vw;
    padding-top: $margin-top-page;
    &__nav {
      width: 95%;
      @include navShop;
    }

    &__main {
      width: 100%;
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      padding-bottom: 2rem;
      &__leftCol {
        width: 75%;
        display: flex;
        flex-direction: column;
        gap: 2rem;
        &__gallery {
        }
        &__technical {
          width: 95%;
          margin-left: 1.6rem;
          background: white;
          color: $tertiary-color;
          &__nav {
            display: flex;
            flex-direction: row;
            list-style: none;
            border-bottom: 2px solid rgb(199, 199, 199);
            & > * {
              cursor: pointer;
              padding: 0.5rem 1rem;
              border-bottom: 4px solid transparent;
              &:hover {
                border-bottom: 4px solid $primary-color;
              }
            }
          }
          &__content {
            display: flex;
            flex-direction: column;
            &__list {
              display: flex;
              flex-direction: column;
              padding: 1rem;
              gap: 0.5rem;

              & > li {
                display: flex;
                flex-direction: column;
                gap: 0.5rem;
                list-style: none;
              }
            }

            &__text {
              & > * {
                display: flex;
                flex-direction: column;
                gap: 0.5rem;
              }
            }
          }
        }
        &__similar,
        &__additional {
          width: 95%;
          margin-left: 1.6rem;
          &__content {
            @include productGrid;
          }
        }
      }
      &__rightCol {
        position: relative;
        width: 25%;
        z-index: 10;
        &__content {
          position: fixed;
          top: 7vh;
          width: 23%;
          display: flex;
          flex-direction: column;
          justify-content: flex-start;
          margin-right: 1.6rem;
          gap: 0.2rem;
          & > * {
            background: rgba($tertiary-color, 0.1);
            padding: 1rem;
            display: flex;
            flex-direction: column;
          }
          &__contact {
            display: flex;
            flex-direction: column;
            gap: 0.5rem;
            &__title {
              font-weight: 600;
            }
          }
          &__header {
            justify-content: space-between;
            gap: 1rem;
            &__title {
              font-size: 1rem;
              font-family: 'GameCube';
            }
            &__info {
              display: flex;
              flex-direction: column;
              gap: 0.5rem;
              font-size: 0.7rem;
            }
            &__price {
              // font-weight: 600;
            }
          }
          &__option {
            display: flex;
            flex-direction: column;
            gap: 1rem;
            &__menu {
              display: flex;
              gap: 0.5rem;
              & > * {
                flex: 1 1 auto;
              }
              & button {
                width: 80%;
              }

              // & > label {
              //   margin-bottom: 0.5rem;
              //   font-weight: bold;
              // }
              // & > select {
              //   padding: 0.5rem;
              //   border-radius: 4px;
              //   color: #333;
              //   cursor: pointer;
              //   transition: border-color 0.3s ease-in-out;
              //   font-size: 1rem;
              //   &:focus {
              //     outline: none;
              //     box-shadow: 0 0 0 2px #99c7ff;
              //     border-color: #007bff;
              //   }
              // }
            }
            &__selectedOption {
              &__desc {
                margin-bottom: 0.25rem;
              }
            }
          }
          &__description {
            display: flex;
            flex-direction: column;
            gap: 1rem;
            font-size: 1rem;
            padding: 0.5rem 0;
            line-height: 1.4;
            &--title {
              font-weight: 600;
            }
          }
          &__order {
            flex-direction: row;
            &__header {
              width: 100%;
              display: flex;
              justify-content: center;
              align-items: center;
              gap: 1rem;
              @media (max-width: 840px) {
                flex-direction: column;
                gap: 0.5rem;
              }
              &__quantity {
                @media (max-width: 1280px) {
                  font-size: 0.75rem;
                }
                @media (max-width: 1024px) {
                  font-size: 0.5rem;
                }
              }
              &__price {
                display: flex;
                justify-content: center;
                align-items: flex-end;
                flex-wrap: none;
                @media (max-width: 1280px) {
                  font-size: 0.75rem;
                }
                @media (max-width: 1024px) {
                  font-size: 0.5rem;
                }
                & span {
                  font-weight: 600;
                  font-size: 2rem;
                  @media (max-width: 1280px) {
                    font-size: 1.5rem;
                  }
                  @media (max-width: 1024px) {
                    font-size: 1.25rem;
                  }
                }
              }
            }
          }
          &__addToCart {
            cursor: pointer;
            width: 100%;
            height: 35px;
            padding: 0.5rem 0 0.5rem 1.5rem;
            display: flex;
            flex-direction: row;
            justify-content: flex-start;
            align-items: center;
            gap: 1rem;
            background: $primary-color;
            font-weight: 600;
            transition: all 0.3s ease-in-out;
            @media (max-width: 1024px) {
              padding: 0.5rem;
              gap: 0.8rem;
              font-size: 0.9rem;
            }
            @media (max-width: 880px) {
              gap: 0.6rem;
              font-size: 0.8rem;
            }
            & > svg {
              width: 25px;
              @media (max-width: 1024px) {
                width: 25px;
              }
            }
            &:hover {
              filter: brightness(1.1);
              box-shadow: inset 0px 0px 5px 1px rgba(0, 0, 0, 0.3);
            }
          }
          &__footer {
            display: flex;
            &__left {
              width: 50%;
              display: flex;
              flex-direction: column;
              justify-content: flex-end;
              font-size: 14px;
            }
            &__right {
              width: 50%;
              display: flex;
              flex-direction: column;
              align-items: flex-end;
              &__price {
                font-size: 24px;
                font-weight: bold;
                margin: 0 0 10px 0;
              }

              &__btn {
                background-color: #ff5722;
                color: #fff;
                border: none;
                border-radius: 5px;
                padding: 10px 20px;
                font-size: 16px;
                cursor: pointer;
                transition: background-color 0.3s ease;
                display: block;
                margin-top: 20px;
                &:hover {
                  background-color: #e64a19;
                }
              }
            }
          }
          &__advantage {
            display: flex;
            flex-direction: column;
            gap: 1rem;
            &__title {
            }
            &__name {
              display: flex;
              flex-direction: column;
              gap: 0.5rem;
              font-size: 1.1rem;
              font-weight: 600;
              color: $secondary-color;
            }
          }
        }
      }
    }
  }
}

@mixin box {
  background: rgba($tertiary-color, 0.1);
  border-radius: 3px;
}

@mixin navShop {
  &__link {
    width: 100%;
    display: flex;
    text-align: left;
    align-items: center;
    gap: 0.2rem;
    font-size: 0.8rem;
    & a {
      text-decoration: none;
      &:hover {
        text-decoration: underline;
      }
    }
    &__productName {
      color: $primary-color;
      font-size: 0.9rem;
      font-weight: 600;
    }
  }
}

@mixin productGrid {
  &__productGrid {
    width: 100%;
    padding: 1rem 0;
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
    grid-gap: 1rem;
    list-style: none;
    @include miniCard;
  }
}

@mixin miniCard {
  &__product {
    position: relative;
    background: white;
    color: $tertiary-color;
    list-style: none;
    border-radius: 3px;
    &__productCard {
      display: flex;
      flex-direction: column;
      align-items: center;
      box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
      height: 22vw;
      &:hover &__image {
        & img {
          transform: scale(1.1);
        }
      }
      &__image {
        width: 100%;
        height: 12vw;
        overflow: hidden;
        border-radius: 3px 3px 0 0;
        & > img {
          width: 100%;
          height: 100%;
          object-fit: cover;
          transition: all 0.3s ease-in-out;
        }
      }
      &__content {
        display: flex;
        flex-direction: column;
        gap: 0.5rem;
        height: 10vw;
        padding: 0.5rem;
        &__header {
          & > h3 {
            font-size: 1.2rem;
            text-align: center;
          }
          &__price {
            position: absolute;
            top: 0.5rem;
            right: 0.5rem;
            font-size: 1rem;
            color: white;
            text-align: center;
            background: rgba($tertiary-color, 0.8);
            padding: 0.3rem 0.7rem;
            border-radius: 3px;
            & > span {
              font-size: 1.2rem;
              font-weight: bold;
            }
          }
        }
        &__description {
          font-size: 0.8rem;
          overflow: hidden;
          & > span {
            font-weight: bold;
          }
        }
      }
    }
  }
}

@mixin shippingAddress {
  &__shippingAddress {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 0.5rem;
    &__content {
      width: 100%;
      display: flex;
      flex-wrap: wrap;
      gap: 1%;
      &__modifyBtn {
        position: absolute;
        display: flex;
        align-items: flex-end;
        bottom: 0;
        right: 0;
      }
      &__address {
        position: relative;
        display: flex;
        justify-content: space-between;
        flex: 0 0 100%;
        margin: 0.5% 0;
        padding: 0.5rem;
        // border: 1px solid rgba($tertiary-color, 0.2);
        border-radius: 3px;
        box-shadow: 0px 0px 5px rgba($tertiary-color, 0.1);
        cursor: pointer;
        &:hover {
          background: white;
        }
        &:hover #editSvg {
          fill: $tertiary-color;
        }
        &:hover #deleteSvg {
          fill: $tertiary-color;
        }
        &__info {
          &__name {
            display: flex;
            flex-wrap: wrap;
            gap: 0.33rem;
          }
        }
        &__form {
          width: 100%;
          &__btn {
            display: flex;
            flex-direction: row;
            justify-content: center;
            gap: 1rem;
            margin-top: 1rem;
          }
        }
      }
    }
    &__form {
      width: 100%;
      &__btn {
        display: flex;
        flex-direction: row;
        justify-content: center;
        gap: 1rem;
        & > * {
          width: 100px;
        }
      }
      &__message {
        font-size: 14px;
        font-style: italic;
        text-align: right;
        padding-top: 0.3rem;
      }
      &__errorMsg {
        color: red;
      }
    }
  }
}

@mixin orders {
  &__orders {
    background: rgba($tertiary-color, 0.1);
    padding: 0.5rem;
    width: 100%;
    & > h2 {
      padding-bottom: 0.5rem;
    }
    &__table {
      width: 100%;
      overflow-x: auto;
      gap: 1rem;
      &__status {
        display: flex;
        justify-content: space-between;
        gap: 0.5rem;
      }
      & > table {
        width: 100%;
        & > tbody {
          & > tr {
            &:hover {
              background: rgba($primary-color, 0.2);
            }
          }
        }
        & td {
          width: 1px;
          white-space: nowrap;
        }
      }
      &__shippingAddress {
        margin-bottom: 0.5rem;
      }
      &__detail {
        display: flex;
        flex-direction: column;
        gap: 0.5rem;
        &__title {
          & > span {
            font-weight: 700;
          }
        }
        &__addressInfos {
          display: flex;
          flex-wrap: wrap;
          gap: 0.5rem;
        }
      }
      &__statusBar {
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 95%;
        background: white;
        margin: 1rem;
        &__statusStep {
          flex: 1;
          background: white;
          text-align: center;
          padding: 0.33rem;
          color: rgba($tertiary-color, 0.6);
        }
        & > .active {
          background: $primary-color;
          font-weight: bold;
          font-size: 0.9rem;
          color: $tertiary-color;
        }
      }
    }
  }
}

@mixin cartDetails {
  &__cartDetails {
    @include box;
    padding: 1rem;
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 1rem;
    & button {
      width: 10rem;
    }
    &__product {
      width: 100%;
      display: flex;
      padding: 0.5rem;
      border-radius: 3px;
      &__image {
        width: 150px;
        @media (max-width: 480px) {
          width: 120px;
        }
        & > img {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }
      &__content {
        width: 100%;
        display: flex;
        flex-direction: column;
        padding: 0.5rem;
        @media (max-width: 480px) {
          padding: 0;
        }
        &__header {
          display: flex;
          justify-content: space-between;
          gap: 0.5px;
          &__name {
            & h4 {
              font-weight: 500;
              @media (max-width: 480px) {
                font-size: 0.9rem;
              }
            }
            & h4:hover {
              text-decoration: underline 1px;
            }
            & > p {
              color: rgb(0, 200, 0);
              font-size: 0.75rem;
            }
          }
          &__price {
            display: flex;
            flex-direction: column;
            align-items: flex-end;
            padding: 0.2rem;
            & > p:first-child {
              font-size: 0.75rem;
              color: rgba($tertiary-color, 0.5);
              @media (max-width: 480px) {
                font-size: 0.7rem;
              }
            }
            & > p:nth-child(2) {
              color: red;
              font-weight: 600;
              font-size: 1.1rem;
              @media (max-width: 480px) {
                font-size: 1rem;
              }
            }
          }
        }
        &__footer {
          display: flex;
          justify-content: flex-end;
          align-items: center;
          font-size: 0.8rem;
          gap: 1rem;
          &__delete {
            color: #096ec8;
            text-decoration: underline;
            cursor: pointer;
            &:hover {
              text-decoration: none;
            }
          }
          &__quantity {
            & > input {
              transform: scale(0.9);
              width: 50px;
              text-align: center;
              color: $tertiary-color;
              font-size: 0.75rem;
              border: 1px solid rgba($tertiary-color, 0.2);
            }
          }
        }
      }
    }
  }
}

@mixin summary {
  &__summary {
    background: $tertiary-color;
    border-radius: 3px;
    max-width: 320px;
    & > h2 {
      text-align: center;
      width: 100%;
      font-weight: 600;
      margin: 1rem 0;
    }
    width: 20%;
    position: fixed;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    &__content {
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 1rem;
      width: 100%;
      min-width: 200px;
      padding: 1rem;
      border-radius: 3px;
      & button {
        width: 80%;
      }
    }
  }
}

@mixin customerInfo {
  &__customerInfo {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 1rem;
    &__orderInfos {
      display: flex;
      flex-direction: column;
      width: 95%;
      & label {
        font-weight: 700;
      }
    }
    &__form {
      &__message {
        font-size: 14px;
        font-style: italic;
        text-align: right;
      }
      &__errorMsg {
        color: red;
      }
    }
    & > * {
      // flex: 0 0 50%;
      width: 100%;
    }
    &__billing {
      @include box;
      width: 100%;
      padding: 0.5rem;
      &__billingAddress {
        padding: 1rem;
        & h4 {
          padding: 0.5rem 0;
        }
      }
      &__title {
        margin: 1rem 0;
      }
      &__address {
        & > p {
          margin: 0.1rem 0;
        }
      }
    }
    &__shipping {
      @include box;
      width: 100%;
      padding: 0.5rem;
      &__title {
        display: flex;
        gap: 0.5rem;
        &__checkbox {
          position: relative;
          display: flex;
          flex-direction: row;
        }
      }
      @include shippingAddress;
    }
    &__orderInfos {
      padding: 0.5rem;
      @include box;
      & textarea {
        margin: 0.5rem;
      }
    }
  }
}
