@import '../styles/variables';
@import '../styles/mixins';
@import '~react-image-gallery/styles/scss/image-gallery.scss';

@include product;

.product {
  &__card {
    &__similar {
      width: 96vw;
      &__content {
        & h3 {
          text-align: center;
        }
        &__productGrid {
          width: 100%;
          display: flex;
          justify-content: center;
          gap: 1vw;
          flex-wrap: nowrap;
          overflow-x: hidden;
          overflow-y: hidden;
          // padding: 1vw;
          @include miniCard;
          &__product {
            width: 31vw;
          }
        }
      }
    }
    &__additional {
      width: 96vw;
      &__content {
        & h3 {
          padding: 1rem 0 0.5rem 0;
          text-align: center;
        }
        &__productGrid {
          width: 100%;
          display: flex;
          justify-content: flex-start;
          gap: 1vw;
          flex-wrap: nowrap;
          overflow-x: scroll;
          overflow-y: hidden;
          @include miniCard;
          &__product {
            width: 31vw;
          }
        }
      }
    }
  }
}

.container {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  gap: 5px;
  width: 100%;
  overflow: hidden;
  transition: all 1s ease-in-out;
  &__mainImage {
    margin-top: 10%;
    width: 100%;
    height: 300px;
    background: rgba(#000, 0.2);
  }
  // &__thumbnail {
  //   display: flex;
  //   gap: 5px;

  //   &__image {
  //     flex: 0 0 auto;
  //     width: 100px;
  //     height: 100px;
  //     & img {
  //       width: 100%;
  //       height: 100% !important;
  //       object-fit: cover;
  //     }
  //   }
  // }

  &__thumbnail {
    display: flex;
    transition: transform 0.5s ease-in-out;
    gap: 5px;
    &__image {
      flex: 0 0 auto;
      width: 100px;
      height: 100px;
      & img {
        width: 100%;
        height: 100% !important;
        object-fit: cover;
      }
    }
    & > * {
      & > img {
        width: 100%;
        height: 66vh;
        object-fit: cover;
      }
    }
    & > button {
      position: fixed;
      z-index: 5;
    }
  }
  &__prevBtn {
    top: 40%;
    left: 0;
    width: 50px;
    height: 25px;
  }
  &__nextBtn {
    top: 40%;
    right: 0;
    width: 50px;
    height: 25px;
  }
}

.addProduct {
  width: 80%;
}
