@font-face {
  font-family: 'Lato-Regular';
  src: url('./fonts/Lato/Lato-Regular.ttf') format('truetype');
}

@font-face {
  font-family: 'Lato-Italic';
  src: url('./fonts/Lato/Lato-LightItalic.ttf') format('truetype');
}

@font-face {
  font-family: 'Dancing-script';
  src: url('./fonts/Dancing-script/DancingScript-Regular.ttf')
    format('truetype');
}

@font-face {
  font-family: 'GameCube';
  src: url('./fonts/Gamecube/Gamecube.ttf') format('truetype');
}

@font-face {
  font-family: 'Nasalization';
  src: url('./fonts/Nasalization/nasalization-rg.ttf') format('truetype');
}
