@import './variables';
@import './typography';

body {
  font-family: 'Lato-Regular';
  // background: $tertiary-color;
}

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

.screen {
  min-width: 320px;
}

.errorMsg {
  color: red;
  text-align: center;
}
// li {
//   list-style: none;
// }

.main {
  display: flex;
  justify-content: center;
}

.header {
  position: fixed;
  top: 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: rgba(#000, 0.5);
  width: 100%;
  height: 6vh;
  padding: 0 3%;
  z-index: 5;
  &__shop {
    font-family: 'GameCube';
    // background: rgba($tertiary-color, 0.5);
    padding: 0.6rem 1rem 0.8rem 1rem;
    border-radius: 5px;
    transition: all 0.2s ease-in-out;
    &:hover {
      color: $primary-color;
    }
  }
  &__logo {
    display: flex;
    align-items: center;
    text-align: center;
    & > svg {
      height: 4vh;
    }
  }
  &__nav {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    color: white;
    gap: 2rem;
    height: 4vh;
    // background: rgba($tertiary-color, 0.5);
    padding: 1rem;
    border-radius: 5px;
    & * {
      transition: all 0.1s linear;
    }
    & > a {
      position: relative;
    }
    & svg {
      fill: white;
      stroke: white;
      max-height: 4vh;
      &:hover {
        fill: $primary-color;
        stroke: $primary-color;
      }
    }
    &__cartSvg {
      width: 26px;
      stroke-width: 15;
      &__cartQty {
        display: flex;
        justify-content: center;
        align-items: center;
        position: absolute;
        top: -12%;
        right: -25%;
        background: red;
        font-size: 0.7rem;
        color: white;
        font-weight: 600;
        width: 13px;
        height: 13px;
        border-radius: 6px;
      }
    }
    &__profileSvg {
      stroke-width: 15;
      width: 20px;
    }
    &__contactSvg {
      stroke-width: 15;
      width: 23px;
    }
    &__backSvg {
      width: 23px;
      stroke-width: 15;
    }
  }
}

.footer {
  position: relative;
  bottom: 0;
  background: black;
  width: 100%;
  height: 150px;
  color: white;
}

.background {
  &--dark {
    background: $tertiary-color;
  }
}

button {
  margin: 0.5rem;
  padding: 0.5rem;
  border-radius: 5px;
  font-size: 0.9rem;
  background-color: $primary-color;
  color: $tertiary-color;
  border: none;
  cursor: pointer;
  transition: filter 0.2s ease-in-out;
  font-weight: 600;
  &:hover {
    filter: brightness(1.1);
    box-shadow: inset 0px 0px 5px 1px rgba(0, 0, 0, 0.3);
  }
}

input,
textarea {
  height: auto;
  margin: 7px 0 7px 0;
  padding: 5px;
  border-radius: 3px;
  font-size: 16px;
  overflow: hidden;
  resize: none;
  &::placeholder {
    opacity: 0.8;
    font-size: 0.8rem;
  }
}

form {
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 20px;
  background: white;
  box-shadow: 0px 0px 5px rgba($tertiary-color, 0.1);
  border-radius: 3px;
  & > div {
    display: flex;
    flex-direction: column;
  }
  & > p {
    padding: 0.5rem 0 1rem 0;
  }
  & {
    input,
    textarea {
      background: rgb(240, 240, 240);
      outline: none;
      border: none;
    }
    input:focus,
    textarea:focus {
      outline: 1px solid $primary-color;
      border: 1px solid $primary-color;
    }
    .imagePreview {
      margin: 20px;
      & > img {
        width: 50vw;
        object-fit: contain;
        border: 1px solid $tertiary-color;
        box-shadow: 2px 2px 5px $tertiary-color;
      }
    }
  }
}

.svgButton {
  & svg {
    width: 10px;
    height: 10px;
  }
}

#editSvg,
#deleteSvg,
#cancelSvg,
#confirmSvg {
  width: 15px;
  height: 15px;
  margin: 1px;
  cursor: pointer;
  z-index: 5;
  // fill: rgba($tertiary-color, 0.3);
  &:hover {
    fill: red;
  }
}

.confirmBox {
  z-index: 10;
  position: absolute;
  padding: 1rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
  background: white;
  box-shadow: $tertiary-color 1px 1px 2px;
  text-align: center;
  border-radius: 3px;
  font-weight: 600;
  &__title {
    padding-bottom: 1rem;
  }
  &__btn {
    display: flex;
    justify-content: space-evenly;
    gap: 1rem;
    width: 50%;
  }
}

input[type='number']::-webkit-inner-spin-button {
  opacity: 1;
}

table {
  border: 1px solid black;
  border-collapse: collapse;
  font-size: 0.8rem;
  background: white;
  border-radius: 3px;
  & td {
    border: 1px solid black;
    padding: 0.5rem;
  }
  & th {
    border: 1px solid black;
    padding: 0.5rem;
  }
}

.link {
  &:hover {
    cursor: pointer;
    text-decoration: underline;
  }
}

.isLoading {
  z-index: 10;
  display: inline-block;
  position: absolute;
  top: 50%;
  left: 45%;
  width: 50px;
  height: 50px;
  border: 5px solid rgba($primary-color, 1);
  border-radius: 50%;
  border-top-color: $secondary-color;
  animation: spin 0.8s ease-in-out infinite;
}

@keyframes spin {
  from {
    transform: rotate(360deg);
  }
}
@-webkit-keyframes spin {
  to {
    transform: rotate(360deg);
  }
}

input[type='number'] {
  width: 65px;
  height: 35px;
  padding: 10px;
  font-size: 16px;
  border-radius: 5px;
  border: 2px solid #ddd;
  @media (max-width: 1280px) {
    width: 60px;
    height: 30px;
    padding: 10px;
    font-size: 16px;
    border-radius: 5px;
  }
  @media (max-width: 1024px) {
    font-size: 0.8rem;
  }
}

input[type='number']:focus {
  outline: none;
  border-color: #7fb3d5;
  box-shadow: 0 0 5px #7fb3d5;
}

// .keen-slider__slide {
//   height: 56.25vw;
// }
