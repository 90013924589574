@import '../styles/variables';
@import '../styles/mixins';

.shop {
  & li {
    list-style: none;
  }
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100vw;
  margin-top: $margin-top-page;
  &__header {
    display: flex;
    justify-content: center;
    width: 95%;
    font-family: 'GameCube';
    color: white;
    padding: 1rem 0;
    & h1 {
      font-size: 1.5rem;
    }
  }
  &__content {
    width: 95%;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 1rem;
    &__nav {
      width: 100%;
      @include navShop;
    }
    &__filter {
      display: flex;
      justify-content: flex-start;
      gap: 10px;
      flex-wrap: nowrap;
      width: 100%;
      padding: 0 1%;
      & > * {
        display: flex;
        flex-direction: column;
        justify-content: center;
      }
    }
    &__products {
      display: flex;
      flex-direction: column;
      width: 100%;
      & h2 {
        color: $tertiary-color;
        text-decoration: none;
        &:hover {
          text-decoration: underline $tertiary-color;
        }
      }
      &__tent {
        margin-bottom: 0.5rem;
        &__title {
          padding: 0.5rem 0;
          font-family: 'GameCube';
          font-size: 1.2rem;
        }
        @include productGrid;
      }
      &__accessory {
        &__title {
          padding: 0.5rem 0;
          font-family: 'GameCube';
          font-size: 1.2rem;
        }
        @include productGrid;
      }
    }
  }
}
