@import './variables';

.home {
  display: flex;
  flex-direction: column;
  transition: all 0.5s ease-in-out;
  &__section {
    position: relative;
    width: 100vw;
    height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    transition: all 3s ease-in-out;
    & > h1 {
      position: absolute;
      top: 25%;
      z-index: 2;
      text-shadow: 0 0 15px white;
      font-size: 4em;
    }
    & > a {
      display: flex;
      justify-content: center;
    }
    &__button {
      z-index: 2;
      position: absolute;
      padding: 10px 30px;
      bottom: 3vh;
      font-size: 1rem;
      font-weight: 700;
      background: $primary-color;
      &:hover {
        filter: brightness(1.2);
        box-shadow: 0 0 20px $primary-color;
      }
    }
    &__products {
      display: flex;
      flex-wrap: nowrap;
      justify-content: space-between;
      gap: 0.2rem;
      width: 95vw;
      &__miniCard {
        position: relative;
        width: 18vw;
        height: 18vw;
        list-style: none;
        overflow: hidden;
        &__title {
          display: flex;
          align-items: center;
          justify-content: center;
          position: absolute;
          top: 0;
          width: 100%;
          height: 100%;
          overflow: hidden;
          padding-bottom: 5px;
          background-color: rgba($tertiary-color, 0.3);
          transition: all 0.3s ease-in-out;
          font-family: 'GameCube';
          font-size: 0.8rem;
        }
        & > a {
          & > img {
            width: 100%;
            height: 100%;
            object-fit: cover;
            transition: all 0.5s ease-in-out;
          }
          & > div {
            & > h3 {
              text-align: center;
              color: white;
              transition: all 0.5s ease-in-out;
              font-size: 2em;
            }
          }
          &:hover img {
            transform: scale(1.25);
          }
          &:hover h3 {
            transform: scale(10);
          }
          &:hover .miniCardTitle {
            opacity: 0;
          }
          & > * {
            width: 100%;
            height: 100%;
          }
        }
      }
    }
    &__content {
      transform: scale(0);
      opacity: 0;
      display: flex;
      flex-direction: column;
      width: 75%;
      gap: 2rem;
      padding: 3rem;
      transition: all 0.5s ease-in-out;
      &__name {
        font-family: 'GameCube';
        font-size: 4rem;
        text-align: center;
      }
      &__message {
        font-size: 3rem;
        text-align: center;
      }
    }
  }
}

.showContent {
  transform: scale(1);
  opacity: 1;
}

h1 {
  font-size: 2rem;
}
